import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Testimonial.css'; // Import your custom CSS file

const Testimonial = () => {
    return (
        <div className="container">
            <p class="text-blk map-contactus-head" id="w-c-s-fc_p-1-dm-id" style={{fontSize:"22px" , fontWeight:"500"}}>
                    Testimonials
                </p>
            <Carousel>
                <Carousel.Item>
                    <div >
                        <i>Completely Satisfied, installed High quality CCTV cameras at my farmhouse. Gave 2 years AMC for free.</i>
                        <p>-Mehak Sharma</p>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div >
                        <i>"DigiNXT excels at bringing projects to life. I highly recommend this web agency for top-quality websites."</i>
                        <p>-Ramesh Sunori</p>
                        
                    </div>
                </Carousel.Item>

                <Carousel.Item>
                    <div >
                        <i>Hired these guys for access point systems in my building. Would definitely recommend them. Very professional.</i>
                        <p>-Bhupesh Tondwal</p>
                        
                        
                    </div>
                </Carousel.Item>


                <Carousel.Item>
                    <div>
                        <i>With innovative ideas of professional and dedicated team, they create and provide amazing services</i>
                        <p>-Shivani Singh</p>
                        
                    </div>
                </Carousel.Item>

                <Carousel.Item>
                    <div >
                        <i>Had a great experience with these guys, installed top notch surveillance system at my office.

                        </i>
                        <p>-Dimple Juneja</p>
                        
                    </div>
                </Carousel.Item>

                <Carousel.Item>
                    <div >
                        <i>"We needed a website to match our vision and found DigiNXT Media for fantastic support."

                        </i>
                        <p>-Ashish Kumar</p>
                        
                    </div>
                </Carousel.Item>

                <Carousel.Item>
                    <div >
                        <i>There service is so fast and good that within a couple of days they setup it nicely. I highly appreciate their efforts.

                        </i>
                        <p>-Pushpendra Pundir</p>
                        
                    </div>
                </Carousel.Item>

                <Carousel.Item>
                    <div>
                        <i>Service is really nice. Team is very talented. Helped me a lot to grow my business

                        </i>
                        <p>-Saurabh Agarwal</p>
                        
                    </div>
                </Carousel.Item>

                {/* Add more Carousel.Items for additional testimonials */}
            </Carousel>
        </div>
    );
};

export default Testimonial;
