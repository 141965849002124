import logo from "./photos/NXT.png";
import Dnxt from "./photos/DigiNXT.png";
import inxt from "./photos/InterioNXT.png";
import tnxt from "./photos/TechNXT.png";
import "./App.css";
import Navbar from "./components/Navbar";
import Testimonial from "./components/Testimonial";
import Location from "./components/Location";
import Address from "./components/Address";
import Footer from "./components/Footer";
import Preloader from "./components/Preloader";
import React, { useState, useEffect } from 'react';

function App() {
  const [loading, setLoading] = useState(true);

  // Simulate loading for 2 seconds (replace with actual loading logic)
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div>
      {loading ? <Preloader /> :
        <div className="App">
          <div
            className="Head p-2"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Navbar />
            <p
              style={{
                fontSize: "16px",
                fontWeight: "500",
                maxWidth: "85%",
                textAlign: "center",
              }}
            >
              Unlock the power of Next Gen technology with Next Group India! As a
              leading service provider in IT Infrastructure, Digital Marketing, and
              Interior Designing, we bring innovation and excellence to every
              project. With hundreds of happy clients, we're here to transform your
              business with cutting-edge solutions and stunning designs. Let's
              embark on a journey of success together!
            </p>
          </div>

          <div className="container pb-5">
            <div className="row">
              <div className="col-md-4 d-flex flex-column justify-content-center align-items-center">
                <p></p>
                <div className="bulge-effect">
                  <a
                    className="App-link text-decoration-none"
                    href="https://diginxtmedia.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={Dnxt}
                      style={{ width: "230px" }}
                      alt="logo"
                      className="p-2"
                    />
                    <p className="p-2" style={{ fontSize: "18px", fontWeight: "500", color: "black" }}>
                      Advertise. Analyze. Optimize.
                    </p>
                  </a>
                </div>
                <a href="mailto:info@diginxtmedia.com" className="text-decoration-none">
                  <i className="fas fa-envelope me-3 text-black"></i> <span style={{ color: "black" }}>info@diginxtmedia.com</span>
                </a>
                <div className="p-2">
                  <a
                    href="https://www.facebook.com/diginxtmedia"
                    target="_blank"
                    className="me-4 link-secondary text-black"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/diginxtmedia"
                    target="_blank"
                    className="me-4 link-secondary text-black"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/diginxt-media-pvt-ltd"
                    target="_blank"
                    className="me-4 link-secondary text-black"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </div>
              </div>

              <div className="col-md-4 d-flex flex-column justify-content-center align-items-center">
                <p></p>
                <div className="bulge-effect">
                  <a
                    className="App-link text-decoration-none"
                    href="https://technxtsolutions.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={tnxt}
                      style={{ width: "260px" }}
                      alt="logo"
                      className="p-2"
                    />
                    <p className="p-2" style={{ fontSize: "18px", fontWeight: "500", color: "black" }}>
                      Protect. Connect. Entertain.
                    </p>
                  </a>
                </div>
                <a href="mailto:info@technxtsolutions.com" className="text-decoration-none">
                  <i className="fas fa-envelope me-3 text-black"></i>  <span style={{ color: "black" }}>info@technxtsolutions.com</span>
                </a>
                <div className="p-2">
                  <a
                    href="https://www.facebook.com/technxtsolutions"
                    target="_blank"
                    className="me-4 link-secondary text-black"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/technxt_solutions?igshid=YWJhMjlhZTc%3D"
                    target="_blank"
                    className="me-4 link-secondary text-black"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/90817649/admin/feed/posts/"
                    target="_blank"
                    className="me-4 link-secondary text-black"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </div>
              </div>

              <div className="col-md-4 d-flex flex-column justify-content-center align-items-center">
                <p></p>
                <div className="bulge-effect">
                  <a
                    className="App-link text-decoration-none"
                    href="http://interionxt.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={inxt} style={{ width: "270px" }} alt="logo" />
                    <p className="p-1" style={{ fontSize: "18px", fontWeight: "500", color: "black" }}>
                      Design Better
                    </p>
                  </a>
                </div>
                <a href="mailto: info@interionxt.com" className="text-decoration-none">
                  <i className="fas fa-envelope me-3 text-black"></i> <span style={{ color: "black" }}>info@interionxt.com</span>
                </a>
                <div className="p-2">
                  <a href="" className="me-4 link-secondary text-black">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="" className="me-4 link-secondary text-black">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="" className="me-4 link-secondary text-black">
                    <i className="fab fa-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div style={{ bottom: "0" }} >
            <div style={{ backgroundColor: "rgba(0, 0, 0, 1)", color: "white", bottom: "0", width: "100%" }}>
              <div className="container align-items-end p-3">
                <footer className="row">
                  <div className="col-md-4">
                    <Testimonial />
                  </div>
                  <div className="col-md-4">
                    <Address />
                  </div>
                  <div className="col-md-4 d-flex flex-column">
                    <Location />
                  </div>
                </footer>
              </div>
              <div className="text-center p-2">
                <div className="d-flex justify-content-center flex-wrap">
                  <span className="p-2">
                    Copyright NXT Group India, All Rights Reserved.
                  </span>
                  <span className="p-2">Designed By: DigiNXT Media</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default App;
