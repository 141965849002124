import React from 'react'

const Location = () => {
    return (
        <div>
            <div class="map-part">
                <p class="text-blk map-contactus-head" id="w-c-s-fc_p-1-dm-id">
                    <span style={{ fontSize: "22px", fontWeight: "500" }}>Reach us</span>
                </p>
                <div>
                    <div className='p-1'>
                         <a className='text-decoration-none' style={{ color: "white" }} href="tel:+91 9990544669">+91 9990544669</a> | <a className='text-decoration-none' style={{ color: "white" }} href="tel:+91 99905 44110">+91 99905 44110</a> 
                    </div>
                    <div className='p-1'>
                         <a className='text-decoration-none' style={{ color: "white" }} href="tel:+91 99905 44668">+91 99905 44668</a> | <a className='text-decoration-none' style={{ color: "white" }} href="tel:+91 99905 44993">+91 99905 44993</a>
                    </div>
                    <div className='p-1'>
                         <a className='text-decoration-none' style={{ color: "white" }} href="tel:0124-4047891">  0124-4047891</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Location