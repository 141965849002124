import React from 'react';
import logo from '../photos/NXT.png';

const Preloader = () => {
  return (
    <div className='d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
      <img src={logo} alt='logo' style={{ width: '500px' }} />
    </div>
  );
};

export default Preloader;
