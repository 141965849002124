import React from 'react'
import './Navbar.css'
import logo from "../photos/NXT.png";
const Navbar = () => {
    return (
        <div>
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <img src={logo} alt='logo' style={{width:"100px"}} />
                    </div>
                    <div class="col-md-12 text-center">
                        <h3 class="animate-charcter"> NXT Group India</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar