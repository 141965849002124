import React from 'react'

const Address = () => {
  return (
    <div>
        <div class="map-part">
                <p class="text-blk map-contactus-head" id="w-c-s-fc_p-1-dm-id" style={{fontSize:"22px" , fontWeight:"500"}}>
                    Address
                </p>
                <div class="map-box container-block">
                710/40, HUDA Market, Sector-40, 
                <br/>Gurugram, Haryana-122001

                </div>
                
                
            </div>
    </div>
  )
}

export default Address